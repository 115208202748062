<template>
	<div class="customerHome customerOrderAssessment">
		<div class="chuck">
			<div class="title">
				需求评估
			</div>
			<Form style="margin-top: 20px;" ref="assessmentForm" :model="assessmentForm" label-colon inline>
				<FormItem style='width: 100%;' prop="evaluationOpinion">
					<!-- <Input :disabled="true" maxlength="1000" show-word-limit :rows="15" type="textarea"
						placeholder="评估意见" v-model="assessmentForm.evaluationOpinion" /> -->
					<div class="article" v-html="assessmentForm.evaluationOpinion"></div>
				</FormItem>
				<br>
				<FormItem>
					<p v-if="userInfo.userType != 1 && userInfo.userType != 3" class="label">
						需机构用户管理员（{{userFile.customerAdminName}}）确认</p>

					<!-- <Button :disabled="userInfo.userType != 1 && userInfo.userType != 3" type="primary"
						@click="back()">需求确认</Button> -->

					<!-- 确认需求按钮	 -->
					<ComfirmButton :customerStyle="customerStyle1"
						:disabled="userInfo.userType != 1 && userInfo.userType != 3" ref="ComfirmButton" type='primary'
						info='需求确认' @comfirm='confirm'>
						<div slot="content">
							<div class="sumBox">
								<p class="text">需求确认？</p>
								<!-- <br>
								<Button class="btn" type="primary" @click="confirm">确定</Button> -->
							</div>
						</div>
					</ComfirmButton>

					<!-- 终止按钮 -->
					<ComfirmButton v-if="hasTermination" :customerStyle="customerStyle2"
						@comfirm="termination('terminationButton')" type='default' info='终止'>
						<div slot="content">
							<div style="padding-top: 30px;padding-left: 30px;padding-right: 30px;">
								<Form ref="terminationButton" label-position='top' :rules="terminationRules"
									:model="terminationForm" style="width: 100%;" label-colon>
									<FormItem class='item' prop="terminationReason" label="终止原因">
										<Input type="textarea" :rows="6" maxlength="200" show-word-limit
											v-model="terminationForm.terminationReason" placeholder="终止原因">
										</Input>
									</FormItem>
								</Form>
							</div>
						</div>
					</ComfirmButton>
					<!-- 复制按钮 -->
					<Button v-copy='copyText' type="text">复制文字</Button>
				</FormItem>
			</Form>
		</div>
	</div>
</template>

<script>
	import {
		data_require_detail,
		data_hasTermination,
		data_termination,
		data_confirm
	} from "@/api/customerApi.js";
	import ComfirmButton from '../../components/Comfirm.vue'
	export default {
		name: 'customerOrderAssessment',
		components: {
			ComfirmButton
		},
		data() {
			return {
				assessmentForm: {},
				hasTermination: false,
				terminationForm: {
					terminationReason: ''
				},
				terminationRules: {
					terminationReason: [{
						required: true,
						message: '必填项',
					}],
				},
				customerStyle1: {
					'width': '140px',
					'height': '40px',
				},
				customerStyle2: {
					"margin-left": "20px",
					'width': '140px',
					'height': '40px',
				},
			}
		},
		mounted() {
			this.init()
		},
		computed: {
			requireUid() {
				return this.$route.query.requireUid
			},
			userInfo() {
				let str = localStorage.getItem('userInfo')
				return JSON.parse(str)
			},
			userFile() {
				let str = localStorage.getItem('userFile')
				return JSON.parse(str)
			},
			copyText() {
				// let html = this.assessmentForm.evaluationOpinion ?? ''
				let html = this.assessmentForm.evaluationOpinion?this.assessmentForm.evaluationOpinion:''
				html = html.replace(/<\/p>/g,"\r\n");
				html = html.replace(/<\/div>/g,"\r\n");
				html = html.replace(/<\/table>/g,"\r\n");
				html = html.replace(/<\/h[0-9]>/g,"\r\n");

				// html = html.replace(/(\n)/g, "");
				// html = html.replace(/(\t)/g, "");
				// html = html.replace(/(\r)/g, "");
				html = html.replace(/<\/?[^>]*>/g, "");
				// html = html.replace(/(\s)/g, "");
				html = html.replace(/&nbsp;/ig, " ");
				html = html.replace(/&amp;/ig,'&')
				return html
			}
		},
		methods: {
			init() {
				this.getAssessment()
				this.getTermination()
			},
			async getAssessment() {
				let data = await data_require_detail({
					requireUid: this.requireUid
				})
				this.assessmentForm = data
			},
			async getTermination() {
				let data = await data_hasTermination({
					requireUid: this.requireUid
				})
				this.hasTermination = data
			},
			//终止
			termination(name) {
				this.$refs[name].validate(async (valid) => {
					if (valid) {
						let params = this.terminationForm
						params.requireUid = this.requireUid
						data_termination(params).then(data => {
							this.$msg.error({
								text: "项目已终止"
							})
							this.$refs[name].resetFields()
							setTimeout(() => {
								this.back()
							}, 1000)
						}).catch(err => {
							this.$msg.error({
								text: err
							})
						})
					} else {
						this.$msg.error({
							text: "请输入终止原因"
						})
					}
				})
			},
			async confirm() {
				data_confirm({
					requireUid: this.requireUid
				}).then(res => {
					this.$msg.success({
						text: "操作成功"
					})
					this.$refs.ComfirmButton.cancle()
					setTimeout(() => {
						this.back()
					}, 1000)
				}).catch(err => {
					this.$msg.error({
						text: err.data
					})
					this.$refs.ComfirmButton.cancle()
				})
				// let data = await data_confirm({
				// 	requireUid: this.requireUid
				// })
				// if (data.code != -1) {
				// 	this.$msg.success({
				// 		text: "操作成功"
				// 	})
				// 	this.$refs.ComfirmButton.cancle()
				// 	setTimeout(() => {
				// 		this.back()
				// 	}, 1000)
				// } else {
				// 	this.$msg.success({
				// 		text: data
				// 	})
				// }
			},
			back() {
				this.$router.push({
					path: '/customerorder/customerorder',
					query: {
						tab: 2
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "@/style/customerorder.scss";

	.label {
		font-size: 12px;
		color: red;
	}

	.sumBox {
		text-align: center;
		width: 100%;
		height: 120px;
		line-height: 160px;

		.text {
			width: 100%;
			text-align: center;
			font-size: 18px;
		}

		.btn {
			display: inline-block;
			width: 140px;
			height: 40px;
		}
	}

	.chuck {
		min-height: calc(100vh - 120px);
	}

	.article {
		width: 100%;
		min-height: 650px;
		overflow: auto;
		background-color: #efefef;
		padding: 10px;

	}
</style>
